import * as Cookies from 'js-cookie';
import Vue from 'vue';

import $http from '../utils/http';

// @ts-ignore
Vue.use(Cookies);
// @ts-ignore

const getUser = (user) => {
  return $http(`/user/login`, user, 'POST');
};

const resendOtp = (email: string) => {
  return $http(`/user/login/resend-otp`, { email }, 'POST');
};

// @ts-ignore

const destroySession = (token) => {
  return $http(`/user/login/expire`, { token: token }, 'POST');
};
// @ts-ignore

const getDashboard = (token, companyID = null, userGroup = null) => {
  const header = token ? { authorization: token } : null;
  return $http(`/user/dashboard/${companyID}/${userGroup}`, null, 'GET', header);
};
// @ts-ignore

const registerUser = (token, data) => {
  const header = token ? { authorization: token } : null;
  return $http(`/user/register/`, data, 'POST', header);
};
// @ts-ignore

const getUserProfile = (token) => {
  const header = token ? { authorization: token, 'x-company-header': Cookies.get('companyID') } : null;
  return $http(`/user/profile`, null, 'GET', header);
};
// @ts-ignore

const updateCurrentUserProfile = (token, data) => {
  const header = token ? { authorization: token, 'x-company-header': Cookies.get('companyID') } : null;
  return $http(`/user/profile/`, data, 'PUT', header);
};
// @ts-ignore

const updateStatusUser = (token, adminID, data) => {
  const header = token ? { authorization: token, 'x-company-header': Cookies.get('companyID') } : null;
  return $http(`/user/status/${adminID}`, data, 'PUT', header);
};
// @ts-ignore

const validateUser = (username) => {
  return $http(`user/validate/${username}`, null, 'GET');
};

// @ts-ignore
const validateEmail = (email) => {
  return $http(`user/validate-email/${email}`, null, 'GET');
};
// @ts-ignore

const getChannelInfo = (hashID) => {
  return $http(`/api/user/register/channel/${hashID}`, null, 'GET');
};
// @ts-ignore

const registerUserByLink = (data) => {
  return $http(`/api/user/register/invite`, data, 'POST');
};
// @ts-ignore

const confirmLinkRegistration = (hashID) => {
  return $http(`/api/user/register/invite/confirm/web/${hashID}`, null, 'GET');
};
// @ts-ignore

const sendSmsRegistration = (token, type) => {
  const header = token ? { authorization: token } : null;
  return $http(`/api/user/register/invite/${type}`, null, 'GET', header);
};
// @ts-ignore

const getDeepLink = (token) => {
  const header = token ? { authorization: token } : null;
  return $http(`/api/user/register/deepLinkInsteadOfSms`, null, 'GET', header);
};

const getUsersMessageList = (token: string, skipAmount: number, limitAmount: number) => {
  const header = token ? { authorization: token } : null;

  return $http(`/api/chat/getUsersMessageList?skipAmount=${skipAmount}&limitAmount=${limitAmount}`, null, 'GET', header);
};

const downloadConversationMessageCsv = (token: string, chatMessageID: string, count: number) => {
  const header = token ? { authorization: token } : null;
  const downloadFileOption = { responseType: 'blob' };
  return $http(
    `/user/mobile-users/download-conversation-audit?chatMessageID=${chatMessageID}&count=${count}`,
    null,
    'GET',
    header,
    undefined,
    downloadFileOption
  );
};

const setConversationReadByParticipant = (token: string, chatMessageID: string) => {
  const header = token ? { authorization: token } : null;
  return $http(`/api/chat/setConversationReadByParticipant`, { chatMessageID }, 'POST', header, undefined);
};
export {
  registerUser,
  resendOtp,
  getUser,
  updateCurrentUserProfile,
  destroySession,
  getDashboard,
  getUserProfile,
  updateStatusUser,
  validateUser,
  validateEmail,
  getChannelInfo,
  registerUserByLink,
  confirmLinkRegistration,
  sendSmsRegistration,
  getDeepLink,
  getUsersMessageList,
  downloadConversationMessageCsv,
  setConversationReadByParticipant,
};
